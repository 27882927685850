export default {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#8898AA',
      color: '#444444',
      lineHeight: '36px',
      fontFamily: 'CeraPro, Helvetica Neue, Helvetica, Arial, sans-serif',
      fontSize: '18px',
      fontWeight: '400',
      '::placeholder': {
        color: '#8898AA'
      }
    },
    invalid: {
      iconColor: '#e85746',
      color: '#e85746'
    }
  }
}