import Vue from 'vue'
import axios from 'axios'
import {pick} from 'lodash'
import * as Sentry from '@sentry/browser'

const CancelToken = axios.CancelToken;

/**
 * @param {Object} options - Set of options for the resource endpoint
 * @param {string}       options.name                 Model name, e.g. 'car', 'user'
 * @param {string}       options.path                 Basic path - all routes will be be inferred from this
 * @param {Object}       options.params               Blank object by default otherwise object with GET parameters
 * @param {number}       options.resourceId           Null for a new record, primary id otherwise
 * @param {boolean}      options.singularResource     Some Resources like +Account+ are singular resources
 * @param {boolean}      options.wrapped              Whether JSON responses are wrapped or not
 * @param {boolean}      options.persisted            If true, the initial GET request should goes to +show+. Otherwise to +new+. Only required if +singularResource+ is set to true. Otherweise +resourceId+ will be used
 * @param {Object}       options.inputOptions         Blank object by default and not used internally. Allows passing settings between form and inputs reactively
 * @return {Object} Resource Vue Component that handles CRUD actions.
 */
export default function(options) {

  /**
   * @type {Object} Resource Vue Component that handles CRUD actions. All options will be available as properties in addition to the following
   * @property {Object}     record           Holds all the properties of the record that should be updated/created/...
   * @property {Object}     validation       Holds validation errors if present. Property names will be the keys
   * @property {Object}     errors           Holds HTTP errors id present
   * @property {Object}     errors.get       Holds any HTTP errors that occurred during fetching the record
   * @property {Object}     errors.update    Holds any HTTP errors that occurred during updating the record
   * @property {Object}     errors.create    Holds any HTTP errors that occurred during creation of the record
   * @property {boolean}    isLoading        True as long as there is a pending request
   */
  let resourcesMixin = {
    data() {
      // CRUD configuration
      if(options.record && !options.resourceId) {
        options.resourceId = options.record.id
      }

      let data = {
        name: options.name,
        path: options.path,
        params: options.params || {},
        wrapped: !!options.wrapped,
        skipFetching: !!options.skipFetching,
        resourceId: options.resourceId,
        singularResource: options.singularResource,
        inputOptions: options.inputOptions || {},
        persisted: !!options.persisted,

        record: options.record,
        requestData: {},
        // Since Vue doesn’t allow dynamically adding root-level reactive properties,
        // you have to initialize Vue instances by declaring all root-level reactive data properties upfront,
        // even with an empty value. (https://vuejs.org/v2/guide/reactivity.html#Declaring-Reactive-Properties)
        // 'data' is for reactive data passed around the app and is accessible through 'this.someRessourceObject.data'
        data: {},
        validation: {},
        validationErrorReasons: {},
        errors: {},
        isLoading: false,
        paramsConverter: null,
        success: false,
        multipart: !!options.multipart
      }

      return data
    },

    watch: {
      success() {
        if (!this.success) { return }
        setTimeout(()=>{
          this.success = false
        }, 3000)
      },

      isLoading() {
        if (this.isLoading) {
          this.success = false
        }
      },

      // immediate watcher will run setup directly after initialization
      path: {
        immediate: true,
        handler() { this.setup() }
      },

      // update record if resource id changed - ignore for new records
      resourceId(newValue, oldValue) {
        if (oldValue && newValue != oldValue) {
          this.fetchRecord()
        }
      },
    },

    methods: {

      // PUBLIC

      save({filterFields} = {}) {
        if (this.persisted || this.record.id) {
          return this.update({filterFields})
        } else {
          return this.create({filterFields})
        }
      },

      refresh() {
        this.fetchRecord()
      },

      // PRIVATE

      setup() {
        const csrfToken = document.querySelector('meta[name=csrf-token]')
        if (csrfToken) {
          axios.defaults.headers.common['X-CSRF-Token'] = csrfToken.content
          axios.defaults.timeout = 30000 // 30 sec, like in the mobile app
        }
        if (!this.skipFetching) {
          setTimeout(()=>{
            this.fetchRecord()
          }, 0)
        }
      },

      receivedRecord(record, event) {
        this.isLoading = false
        this.$set(this.errors, event, null)

        this.requestData = record

        if (this.wrapped) {
          this.$emit('receive', record[this.name], event)
        } else {
          this.$emit('receive', record, event)
        }


        this.reset()
      },

      reset() {
        this.validation = {}
        this.validationErrorReasons = {}
        this.errors = {}
      },

      handleError(error, sourceEvent) {
        if (error.__CANCEL__) { // cancelled by us
          return
        }
        this.isLoading = false
        if (error.response?.status === 422 && Array.isArray(error.response.data.errors)) {
          let messages = {}
          let reasons = {}

          error.response?.data.errors.forEach((error) => {
            messages[error.name] = error.messages || ['']
            reasons[error.name] = error.reasons || ['']
          })

          this.$emit('validation', messages)
          this.$emit('validation-reason', reasons)
        } else if (error.response?.status === 422 && typeof error.response.data.errors === "object"){
          let messages = {}
          let reasons = {}

          Object.entries(error.response.data.errors).forEach((error) => {
            messages[error[0]] = error[1]
            reasons[error[0]] = error[1]
          })

          this.$emit('validation', messages)
          this.$emit('validation-reason', reasons)
        } else {
          Sentry.captureException(error)
          this.$emit('error', sourceEvent, error)
        }
      },

      // HTTP handling

      fetchRecord() {
        this.isLoading = true
        let getRoute;
        if (this.persisted || this.resourceId) {
          getRoute = this.routes.edit.replace(':id', this.resourceId)
        } else {
          getRoute = this.routes.new
        }

        let cancelRequest

        const params = {
          cancelToken: new CancelToken((c) => {
            // An executor function receives a cancel function as a parameter
            cancelRequest = c
          }),
          params: this.params
        }

        let requestPromise = axios.get(getRoute, params).then((response) => {
          this.receivedRecord(response.data, 'get')
        }).catch(error => {
          this.handleError(error, 'get')
        })
        this.cancelRequest = cancelRequest

        return requestPromise
      },

      create({ payload, filterFields } = {}) {
        this.isLoading = true
        let params = payload || this.filterParams(this.record, filterFields)
        return axios.post(this.routes.create, params, this.requestOptions()).then(response => {
          this.receivedRecord(response.data, 'create')
          this.success = true
        }).catch(error => {
          this.handleError(error, 'create')
        })
      },

      update({ payload, filterFields } = {}) {
        this.isLoading = true
        let params = payload || this.filterParams(this.record, filterFields)
        return axios.patch(this.routes.update.replace(':id', this.resourceId), params, this.requestOptions()).then(response => {
          this.receivedRecord(response.data, 'update')
          this.success = true
        }).catch(error => {
          this.handleError(error, 'update')
        })
      },

      destroy() {
        this.isLoading = true
        return axios.delete(this.routes.destroy.replace(':id', this.resourceId)).then(response => {
          this.receivedRecord(response.data, 'destroy')
        }).catch(error => {
          this.handleError(error, 'destroy')
        })
      },

      filterParams(record, filterFields) {
        let params
        if (filterFields && filterFields.length > 0) {
          params = _.pick(record, filterFields)
        } else {
          params = record
        }

        if (this.paramsConverter) {
          params = this.paramsConverter(params)
          if(this.wrapped && !this.multipart) {
            let wrappedParams = {}
            wrappedParams[this.name] = params
            params = wrappedParams
          }
        }

        if (!this.multipart) {
          // TODO: wrap??
          return params
        }

        // use FormData to support file uploads
        let formData = new FormData()
        for (let [key, value] of Object.entries(params)) {
          if(this.wrapped) {
            key = `${this.name}[${key}]`
          }
          if (value == null) {
            value = ''
          }
          this.appendFormData(formData, key, value)
        }
        return formData
      },

      appendFormData(formData, key, value) {
        if (Array.isArray(value)) {
          for(let index in value) {
            this.appendFormData(formData, `${key}[]`, value[index])
          }
        } else if (typeof value == 'object' && !(value instanceof File)){
          // extract nested objects
          for(let [nestedKey, nestedValue] of Object.entries(value)) {
            this.appendFormData(formData, `${key}[${nestedKey}]`, nestedValue)
          }
        } else {
          formData.append(key, value)
        }
      },

      requestOptions() {
        if (!this.multipart) { return }
        return {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      }

    },

    created() {
      this.$on('receive', (record, event) => {
        this.resourceId = record.id
        this.record = record
        this.$emit(event, record)
      })

      this.$on('error', (sourceEvent, error) => {
        this.$set(this.errors, sourceEvent, error)
      })

      this.$on('validation', (validation) => {
        this.validation = validation
      })

      this.$on('validation-reason', (reasons) => {
        this.validationErrorReasons = reasons
      })
    },

    computed: {
      routes() {
        let extension = '.json'
        if (this.path.indexOf('?') > 0 || this.path.indexOf('.json') > 0) {
          extension = ''
        }
        if (this.singularResource) {
          return {
            new: `${this.path}${extension}`,
            create: `${this.path}${extension}`,
            edit: `${this.path}${extension}`,
            update: `${this.path}${extension}`,
            destroy: `${this.path}${extension}`
          }
        } else {
          return {
            new: `${this.path}/new${extension}`,
            create: `${this.path}${extension}`,
            edit: `${this.path}/:id${extension}`,
            update: `${this.path}/:id${extension}`,
            destroy: `${this.path}/:id${extension}`
          }
        }
      }
    }
  }

  return new Vue(resourcesMixin)
}
