export default function (locale) {
  locale = locale || 'en'

  const list = {
    de: require('./de.json'),
    en: require('./en.json'),
    nl: require('./nl.json'),
    es: require('./es.json'),
    it: require('./it.json'),
    no: require('./no.json')
  }

  return list[locale] || list['en']
}
