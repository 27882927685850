export default function(stripeKey, callback) {
  let scriptId = "stripe-script"
  if (window.Stripe || document.getElementById(scriptId)) {
    callback()
    return
  }

  let script = document.createElement("script")
  script.type = "text/javascript"
  script.src = "https://js.stripe.com/v3/"
  script.id = scriptId
  script.onload = callback

  document.body.appendChild(script)
}
