import Vue from 'vue'
import stripeLoader from './stripe_loader'

export default function(stripeKey, callback) {
  stripeLoader(stripeKey, () => {
    if (!Vue.prototype.$stripe) {
      Vue.prototype.$stripe = Stripe(stripeKey)
      Vue.prototype.$stripeElements = Vue.prototype.$stripe.elements({
        fonts: [
          {
            family: 'CeraPro',
            src: 'url(/fonts/CeraPro-Regular.woff2)'
          }
        ]
      })
    }
    callback()
  })
}
